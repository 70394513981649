var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "preloader"
  }, [_c('header-loading'), _c('div', {
    staticClass: "preloader-main"
  }, [_c('div', {
    staticClass: "preloader-main-header"
  }, [_c('vue-skeleton-loader', {
    staticClass: "mt-6",
    staticStyle: {
      "margin-bottom": "25px"
    },
    attrs: {
      "type": "rect",
      "width": 165,
      "height": 30,
      "rounded": true,
      "radius": 5,
      "animation": "wave"
    }
  }), _c('vue-skeleton-loader', {
    staticClass: "mr-2",
    staticStyle: {
      "margin-bottom": "14px"
    },
    attrs: {
      "type": "rect",
      "width": 505,
      "height": 125,
      "rounded": true,
      "radius": 5,
      "animation": "wave"
    }
  }), _c('vue-skeleton-loader', {
    staticClass: "mt-6",
    attrs: {
      "type": "rect",
      "width": 360,
      "height": 15,
      "rounded": true,
      "radius": 5,
      "animation": "wave"
    }
  })], 1), _c('vue-skeleton-loader', {
    staticClass: "mt-6",
    staticStyle: {
      "margin-top": "60px"
    },
    attrs: {
      "type": "rect",
      "width": 150,
      "height": 35,
      "rounded": true,
      "radius": 5,
      "animation": "wave"
    }
  }), _c('div', {
    staticClass: "preloader-main-items"
  }, _vm._l(6, function (i) {
    return _c('div', {
      key: i,
      staticClass: "preloader-main-items-item"
    }, [_c('vue-skeleton-loader', {
      attrs: {
        "type": "rect",
        "width": 368,
        "height": 304,
        "rounded": true,
        "radius": 20,
        "animation": "wave"
      }
    }), _c('div', {
      staticClass: "w-full",
      staticStyle: {
        "padding-right": "17px",
        "padding-left": "17px"
      }
    }, [_c('div', {
      staticClass: "flex justify-between w-full items-center"
    }, [_c('vue-skeleton-loader', {
      staticClass: "block",
      attrs: {
        "type": "rect",
        "width": 95,
        "height": 20,
        "rounded": true,
        "radius": 5,
        "animation": "wave"
      }
    }), _c('vue-skeleton-loader', {
      staticClass: "block",
      attrs: {
        "type": "rect",
        "width": 85,
        "height": 34,
        "rounded": true,
        "radius": 5,
        "animation": "wave"
      }
    })], 1), _c('div', {
      staticClass: "flex w-full items-center"
    }, [_c('vue-skeleton-loader', {
      staticClass: "block",
      staticStyle: {
        "margin-right": "20px"
      },
      attrs: {
        "type": "rect",
        "width": 95,
        "height": 20,
        "rounded": true,
        "radius": 5,
        "animation": "wave"
      }
    }), _c('vue-skeleton-loader', {
      staticClass: "block",
      attrs: {
        "type": "rect",
        "width": 120,
        "height": 20,
        "rounded": true,
        "radius": 5,
        "animation": "wave"
      }
    })], 1), _c('div', {
      staticClass: "flex w-full items-center"
    }, [_c('vue-skeleton-loader', {
      staticClass: "block",
      staticStyle: {
        "margin-right": "20px"
      },
      attrs: {
        "type": "rect",
        "width": 95,
        "height": 20,
        "rounded": true,
        "radius": 5,
        "animation": "wave"
      }
    }), _c('vue-skeleton-loader', {
      staticClass: "block",
      attrs: {
        "type": "rect",
        "width": 150,
        "height": 20,
        "rounded": true,
        "radius": 5,
        "animation": "wave"
      }
    })], 1)])], 1);
  }), 0)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }